/* eslint-disable react/display-name */
import React, {useContext, useMemo} from 'react';
import {IStyles} from '@wix/tpa-settings';
import {useStyles} from '@wix/tpa-settings/react';

interface AllStylesContext {
  stylesForAllBreakpoints: IStyles[];
  customCssVarsForAllBreakpoints: Record<string, string>[];
}

const AllStylesContext = React.createContext<AllStylesContext>({} as AllStylesContext);

export function withAllStyles(Component) {
  return (props) => {
    const styles = useStyles();
    const memoizedGetStylesForAllBreakpoints = useMemo(() => styles.getStylesForAllBreakpoints(), [styles]);
    const memoizedGetCustomCssVarsForAllBreakpoints = useMemo(
      () => styles.getCustomCssVarsForAllBreakpoints(),
      [styles]
    );

    return (
      <AllStylesContext.Provider
        value={{
          stylesForAllBreakpoints: memoizedGetStylesForAllBreakpoints,
          customCssVarsForAllBreakpoints: memoizedGetCustomCssVarsForAllBreakpoints,
        }}>
        <Component {...props} />
      </AllStylesContext.Provider>
    );
  };
}

export function useAllStyles() {
  return useContext(AllStylesContext);
}
