/* istanbul ignore file */
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import _ from 'lodash';
import {productItemCustomCssVars} from './common/components/ProductItem/ProductItem.cssVars';
import {carouselCustomCssVars} from './common/components/Carousel/Carousel.cssVars';
import {heroCustomCssVars} from './category/components/Hero/Hero.cssVars';
import {sliderCustomCssVars} from './sliderGallery/components/SliderGallery/Slider.cssVars';
import {BooleanStyleParamsKeys} from './styleParams/types';

export type CustomCssVarsFnParams = Parameters<CustomCssVarsFn>[0];

export type CssVarsKeys = keyof ReturnType<typeof getBaseCustomCssVars> | BooleanStyleParamsKeys;

const getBaseCustomCssVars = ({styleParams: styles, isMobile, isRTL}: CustomCssVarsFnParams) => {
  return {
    filtersHeaderPaddingBottom: styles.booleans.gallery_showFiltersTitle ? '20px' : '0',
    showTitleWasTurnedOn: styles.booleans.gallery_showTitle === true,
    showTitleWasNeverChanged: styles.booleans.gallery_showTitle === undefined,
    ...sliderCustomCssVars({styleParams: styles, isMobile, isRTL}),
    ...heroCustomCssVars({styleParams: styles, isMobile, isRTL}),
    ...carouselCustomCssVars({styleParams: styles, isMobile, isRTL}),
    ...productItemCustomCssVars({styleParams: styles, isMobile, isRTL}),
  };
};

const generateDisplayBooleans = (booleans) => {
  const result = {};
  for (const key of Object.keys(booleans)) {
    const newKey = `${key}-display`;
    const value = booleans[key];
    result[newKey] = value ? 'revert' : 'none';
  }
  return result;
};

export const baseCustomCssVars = (params: CustomCssVarsFnParams) => {
  const baseCssVars = getBaseCustomCssVars(params);
  const booleanCssVars = _.chain(baseCssVars)
    .pickBy((cssVar) => typeof cssVar === 'boolean')
    .value();

  const displayBooleans = generateDisplayBooleans({...params.styleParams.booleans, ...booleanCssVars});
  return {...baseCssVars, ...displayBooleans};
};
